import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, useIonViewDidEnter, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { appGlobalsUserToken } from '../appGlobals';
import { DataContext } from '../context/DataContext';
import { setDataByApi } from '../functions/setDataByApi';
import { caretDownOutline, caretUpOutline, informationCircleOutline } from "ionicons/icons";

let sortChange:any = [] ;
export let resetSortChange = () =>
  {
    sortChange = [ '' , '' ] ;
  }

type CompPageItemProps = {
  itemID: number ,
  itemLabel: any ,
  pageKey: string ,
  appItemSelectChange: Function ,
  itemActiveState: string ,
  itemSetData: any ,
  apiUrl: string  ,
  apiCall: string  ,
  setIntFetchSetTime: Function ,
  mkSortierung?: boolean
}
export const CompPageItem:FunctionComponent<CompPageItemProps> = ( { itemID , itemLabel ,pageKey , appItemSelectChange , itemActiveState , itemSetData , apiUrl, apiCall , setIntFetchSetTime , mkSortierung = false } ) => {
  const {
  } = useContext(DataContext);
  const [ entryColor , setEntryColor ] = useState( '' ) ;
  const saveToDb = ( data:any ) =>
    {
      setDataByApi(
        apiUrl ,
        apiCall ,
        appGlobalsUserToken ,
        data ,
        () => {} ,
        setIntFetchSetTime
      )
      ;
    }
  const truthyLog = ( msg:string ) =>
    {
      console.log( msg , itemLabel , sortChange ) ;
      return true ;
    }
  const hightlightSorted = () =>
    {
      if( sortChange[ 0 ] == pageKey && sortChange[ 1 ] === itemID ) {
        return 'light' ;
      }
      return '' ;
    }
  useEffect(
    () =>
      {
        if( sortChange[ 0 ] == pageKey && sortChange[ 1 ] === itemID ) {
          setEntryColor( 'light' ) ;
        }
      }
      ,
      [ sortChange ]
  )
  useIonViewWillLeave(
    () =>
      {
        setEntryColor( '' ) ;
      }
  )
  return (
    <IonItem
      color = { hightlightSorted() }
      key = { pageKey + '_' + itemID }
    >
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonItem
            lines = "none"
            style =
              {
                itemActiveState === 'true'
                  ?
                    {}
                  : 
                    {
                      color: 'lightgray' ,
                      textDecoration: 'line-through'
                    }
              }
          >
            {
              itemLabel
            }
          </IonItem>
        </IonCol>
        <IonCol
          size = "2"
        >
          <IonButton
            onClick = {
              () =>
                {
                  appItemSelectChange( itemID ) ;
                }
            }
            routerLink =  { '/' + pageKey + '_erstellen' }
          >
            Bearbeiten
          </IonButton>
        </IonCol>
        <IonCol
          size = "2"
        >
          <IonButton
            onClick = {
              () =>
                {
                  resetSortChange() ;
                  saveToDb( itemSetData ) ;
                }
            }
          >
            {
              itemActiveState === 'true'
                ?
                  'Deaktivieren'
                :
                  'Aktivieren'
            }
          </IonButton>
        </IonCol>
        {
          mkSortierung
            ?
              <>
                <IonCol
                  size = "3"
                >
                  <IonButton
                    onClick = {
                      () =>
                        {
                          if( itemSetData[ pageKey + '_sortierung' ] > 0 ) {
                            --itemSetData[ pageKey + '_sortierung' ] ;
                            const my_data = JSON.parse( JSON.stringify( itemSetData ) ) ;
                            let aktivKey = pageKey + '_aktiv' ;
                            my_data[ aktivKey ] = itemSetData[ aktivKey ] === 'true' ? 'false' : 'true' ;
                            // @ts-ignore
                            sortChange = [ pageKey , itemID.toString() ] ;
                            saveToDb( my_data ) ;
                          }
                        }
                    }
                  >
                    <IonIcon icon = { caretUpOutline } />
                  </IonButton>
                  <IonButton
                    onClick = {
                      () =>
                        {
                          ++itemSetData[ pageKey + '_sortierung' ] ;
                          const my_data = JSON.parse( JSON.stringify( itemSetData ) ) ;
                          let aktivKey = pageKey + '_aktiv' ;
                          my_data[ aktivKey ] = itemSetData[ aktivKey ] === 'true' ? 'false' : 'true' ;
                          // @ts-ignore
                          sortChange = [ pageKey , itemID.toString() ] ;
                          saveToDb( my_data ) ;
                        }
                    }
                  >
                    <IonIcon icon = { caretDownOutline } />
                  </IonButton>
                  <IonButton
                    onClick =
                      {
                        () =>
                          {
                            alert(
                              '\n'
                              + 'Sortierreihenfolge'
                              + '\n'
                              + '___________________________________'
                              + '\n\n'
                              + 'kleinere Zahl = weiter oben / vorne'
                              + '\n'
                              + 'größere Zahl = weiter oben / vorne'
                              + '\n'
                              + 'gleiche Zahl = Sortierung nach Titel'
                            ) ;
                          }
                      }
                    fill = "clear"
                    style =
                      {
                        {
                          fontStyle: 'italic'
                        }
                      }
                  >
                    ({ itemSetData[ pageKey + '_sortierung' ] })
                    <IonIcon icon = { informationCircleOutline } />
                  </IonButton>
                </IonCol>
              </>
            :
              null
      }
      </IonRow>
    </IonGrid>
    </IonItem>
  );
};
