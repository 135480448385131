import { IonCardSubtitle, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonTitle, IonToolbar } from '@ionic/react';
import { useContext } from 'react';
import { DataContext } from '../context/DataContext';
import * as Pages from '../pages/index'
import { useHistory  } from "react-router-dom";
import { appGlobalsUser, appGlobalsUserChange, appGlobalsUserToken, appGlobalsUserTokenChange } from '../appGlobals';
import { resetSortChange } from './CompPageItem' ;

const generatedEntries =
  Pages.pagesList
    .map(
      ( menueItem:any ) => {
        return (
          <IonItem
            onClick =
              {
                () => resetSortChange()
              }
            key = { menueItem[ 0 ] }
            routerLink = { menueItem[ 0 ] }
          >
            { menueItem[ 1 ] }
          </IonItem>
        )
      }
    )
;

export const CompMenu = () => {
  const {
    appLoggedInChange
  } = useContext(DataContext);
  const history = useHistory() ;
  return (
    <IonMenu type="overlay" contentId="main">
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          Parkraumpilot Admin
          <br/>
          <b>{ appGlobalsUser.kunde_name }</b>
          <br/>
          <b>{ appGlobalsUser.usr_vorname } { appGlobalsUser.usr_nachname }</b>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent key = { 'random_' + new Date().valueOf() } >
      <IonList>
        { generatedEntries }
        <IonItem
          key = 'Logout'
          onClick =
            {
              () =>
                {
                  if(
                    window.confirm( '\nSind Sie sicher, dass Sie sich abmelden wollen?\n')
                  )
                    {
                      history.push( '/' ) ;
                      appLoggedInChange( false ) ;
                      appGlobalsUserTokenChange( '' ) ;
                      appGlobalsUserChange( {} ) ;
                    }
                }
            }
          style =
            {
              {
                cursor: 'pointer'
              }
            }
        >
          Abmelden
        </IonItem>
      </IonList>
      <IonItem key="version">
        <IonLabel>Version 0.0.001.3525</IonLabel>
      </IonItem>
    </IonContent>
  </IonMenu>
  );
};
