/**
 * appGlobal
 *  - globale Variablen, die nicht von React überwacht werden sollen
 *    - Überwachung durch React (wie in useContext-Variablen) führt zu
 *      ungewollten Rerenderings
 */

export let appGlobalsUser:any = {} ;
export const appGlobalsUserChange = ( val:object ) => {  appGlobalsUser = val ; }

export let appGlobalsUserToken = '' ;
export const appGlobalsUserTokenChange = ( val:string ) => {  appGlobalsUserToken = val ; }
