import { IonButton, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import { CompPageItem } from '../components/CompPageItem';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';

export const PageTarife: React.FC = () => {
  const {
    appTarifChange ,
    appTarifeListeFetchAnew ,
    appTarifeListeFetchAnewChange
  } = useContext(DataContext);

  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ intFetchSetTime , setIntFetchSetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;
  const [ generatedItems , setGeneratedItems  ] = useState( 'laden ...' ) ;

  const makeLabel = ( id:number , main:string ) =>
    {
      let my_label = main ? main : 'unbenannt (interne ID: ' + id + ')' ;
      return my_label ;
    }
  
  useEffect(
    function initComponent() {
      appTarifeListeFetchAnewChange( true ) ;
    } ,
    []
  ) ;

  useEffect(
    function getTarifeListeData() {
      if( appTarifeListeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/tarife/' ,
          'get_tarife.php?kunde_id=' + appGlobalsUser.usr_kunde + '&all=1' ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchGetTime ,
          '' ,
          {
            fields:
            [
              'tarif_id',
              'tarif_bezeichnung',
              'tarif_aktiv'
            ]
          }
        ) ;
        appTarifeListeFetchAnewChange( false ) ;
      }
    } ,
    [ appTarifeListeFetchAnew ]
  ) ;

  useEffect(
    function renewTarifeListeView() {
      let my_Tarife = Array() ;
      let my_generatedItems:any = 'laden ...' ;
      if( objFetchResult.data.length > 0 ) {
        my_Tarife = objFetchResult.data ;
        my_Tarife
          .sort(
            ( a:any , b:any ) => 
              {
                if ( a[ 1 ] > b[ 1 ] ) { return 1 ; }
                if ( a[ 1 ] < b[ 1 ]  ) { return -1 ; }
                return 0 ;
              }
          )
        my_generatedItems =
          <IonList>
            {
              my_Tarife
                .map(
                  ( entry:any ) =>
                    {
                      return(
                        <CompPageItem
                          key = { 'baustelle_' + entry[ 0 ] }
                          itemID = { entry[ 0 ] }
                          itemLabel = { makeLabel( entry[ 0 ] , entry[ 1 ] ) }
                          pageKey = 'tarif'
                          appItemSelectChange = { appTarifChange }
                          itemSetData =
                            {
                              {
                                tarif_id: entry[ 0 ] ,
                                tarif_aktiv: entry[ 2 ] === 'true' ? 'false' : 'true'
                              }
                            }
                            itemActiveState = { entry[ 2 ] }
                            apiUrl = "https://api.parkraumpilot.de/api/"
                            apiCall = "tarife/set_tarif.php"
                            setIntFetchSetTime = { setIntFetchSetTime }
                        />
                      )
                    }
                )                 
            }
          </IonList>
        ;
      } else {
        my_generatedItems = 'keine Einträge vorhanden' ;
      }
      setGeneratedItems( my_generatedItems ) ;
    } ,
    [ intFetchGetTime ]
  ) ;

  useEffect(
    function renewTarifeListeView() {
      if( intFetchSetTime > 0 ) {
        appTarifeListeFetchAnewChange( true ) ;
      }
    } ,
    [ intFetchSetTime ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Tarife & Gebühren
            {
              intFetchGetTime !== 0
                ?
                  ' Stand: ' + new Date( intFetchGetTime ).toLocaleString()
                :
                  null
            }
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButton
            onClick = {
              () =>
                {
                  appTarifeListeFetchAnewChange( true ) ;
                }
            }
          >
            Liste aktualisieren
          </IonButton>
          <IonButton
            onClick = {
              () =>
                {
                  appTarifChange( 'neu' ) ;
                }
            }
            routerLink = "/tarif_erstellen"
          >
            Neuen Tarif erstellen
          </IonButton>
        </IonToolbar>
      </IonHeader>
    <IonContent>
      <IonCard key="TarifeListe">
        <IonCardContent
        >
          { generatedItems }
        </IonCardContent>
      </IonCard>
    </IonContent>
    </IonPage>
  );
};
