import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";
import { FunctionComponent, useEffect, useState } from "react";
import { appGlobalsUser, appGlobalsUserToken } from "../appGlobals";
import { getDataByApi } from "../functions/getDataByApi";
import { setDataByApi } from "../functions/setDataByApi";

type CompAnlagenProps = {
  typ: string ,
  zuordnung: string
}
export const CompAnlagen:FunctionComponent<CompAnlagenProps> = ( { typ , zuordnung } ) => {

  const [ anlagen , setAnlagen ]:any = useState( [] ) ;
  const [ boolFetchAnew , setBoolFetchAnew ] = useState( false ) ;
  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ intFetchSetTime , setIntFetchSetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;

  const setData = ( my_data:any) =>
    {
      setDataByApi(
        'https://api.parkraumpilot.de/api/' ,
        '/dokumente/set_dokument.php?' ,
        appGlobalsUserToken ,
        my_data ,
        () => {} ,
        setIntFetchSetTime
      )
      ;
    }

  useEffect(
    function initData() {
      setAnlagen( [] ) ;
      // console.log( 'CompAnlagen zuordnung:' , zuordnung ) ;
      if( zuordnung !== 'neu' ) {
        // console.log( 'CompAnlagen FETCH' ) ;
        setBoolFetchAnew( true ) ;
      }
    }
    ,
    [ zuordnung ]
  ) ;

  useEffect(
    function renewArtikelListeData() {
      if( intFetchSetTime > 0 ) {
        setBoolFetchAnew( true ) ;
      }
    } ,
    [ intFetchSetTime ]
  ) ;

  useEffect(
    function getAnlagen() {
      getDataByApi(
        'https://api.parkraumpilot.de/api/dokumente/' ,
        'get_dokumente.php'
          + '?kunde_id=' + appGlobalsUser.usr_kunde 
          + '&verwendung_typ=' + typ
          + '&verwendung_zuordnung=' + zuordnung
          + '&all=1'
        ,
        appGlobalsUserToken ,
        () => {} ,
        setObjFetchResult ,
        setIntFetchGetTime ,
      ) ;
    setBoolFetchAnew( false ) ;
    } ,
    [ boolFetchAnew ]
  ) ;

  useEffect(
    function renewView() {
      // console.log( 'intFetchGetTime' , intFetchGetTime ) ;
      if( objFetchResult.data.length > 0 ) {
        // console.log( 'CompAnlagen objFetchResult.data' , objFetchResult.data ) ;
        let my_anlagen =
          <IonList>
            {
              objFetchResult.data
                .map(
                  ( entry:any , index:number ) =>
                    {
                      return(
                        <IonItem>
                          <IonGrid>
                            <IonRow>
                              <IonCol>
                                <IonItem
                                  lines = 'none'
                                  style =
                                    {
                                      entry[ 2 ] === 'aktiv'
                                        ?
                                          {}
                                        : 
                                          {
                                            color: 'lightgray' ,
                                            textDecoration: 'line-through'
                                          }
                                    }
                                >
                                  { entry[ 1 ] }
                                </IonItem>
                              </IonCol>
                              <IonCol
                                size = "2"
                              >
                                <IonButton
                                  onClick =
                                    {
                                      () =>
                                        {
                                          setData(
                                            {
                                              dokument_id: entry[ 0 ] ,
                                              dokument_aktiv: entry[ 2 ] === 'aktiv' ? 'inaktiv' : 'aktiv'
                                            } ,
                                          )
                                          ;
                                        }
                                    }
                                >
                                  {
                                    entry[ 2 ] === 'aktiv'
                                      ?
                                        'Deaktivieren'
                                      :
                                        'Aktivieren'
                                  }
                                </IonButton>
                              </IonCol>
                              <IonCol
                                size = "2"
                              >
                                <IonButton
                                  onClick =
                                    {
                                      () =>
                                        {
                                          window.open(
                                            'https://api.parkraumpilot.de/api/dokumente/print_dokument.php?dokument_id=' + entry[ 0 ] ,
                                            '_blank'
                                          ) ;
                                        }
                                    }
                                >
                                  Anzeigen
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      )
                    }
                )
            }
          </IonList>
        ;
        setAnlagen( my_anlagen ) ;
      }
    } ,
    [ intFetchGetTime ]
  ) ;

  return(
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          PDF-Anlagen
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        { anlagen }
        <input
          className = "ion-input"
          id = { 'select_pdf_dialog_' + zuordnung }
          type="file"
          accept="application/pdf"
          onChange = {
            ( e:any ) => {
              const reader:any = new FileReader() ;
              reader.readAsDataURL( e.target.files[0] ) ;
              reader.onload = () => {
                // seite.info.bild[ 1 ]( reader.result ) ;
                // console.log( 'e.target.files[ 0 ].name:' , e.target.files[ 0 ].name ) ;
                setData(
                  {
                    dokument_id: 'neu' ,
                    dokument_kunde: appGlobalsUser.usr_kunde ,
                    dokument_dateiname: e.target.files[ 0 ].name ,
                    dokument_inhalt: reader.result.split( ',' )[ 1 ] ,
                    dokument_verwendung_typ: typ ,
                    dokument_zuordnung: zuordnung
                  } ,
                )
              ;
              // @ts-ignore
              document.getElementById( 'select_pdf_dialog_' + zuordnung ).value = '' ;
             }
            }
          }
          style = {
            {
              padding: '1em'
            }
          }
        />
      </IonCardContent>
    </IonCard>
  )
}