import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { createRef, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { MapContainer, Polygon, TileLayer, useMapEvents , Marker, Popup  } from 'react-leaflet'
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/de-de' ;
import { Editor } from '@toast-ui/react-editor';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core' ;
import 'leaflet-easybutton' ;

const svgFaHouseEdited = `<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='house' class='svg-inline--fa fa-house fa-2x ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' style='font-size: 2em;height: 1em;padding-right: 1vw;'><path fill='currentColor' d='M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z'></path></svg>` ;

const appSettings =
  {
    leaflet:
      {
        center: [ 54.0928368 , 13.3792729 ] ,
        initialZoom:
          {
            breakpoints:
              {
                480: 14 ,
                0: 13
              }
          }
        ,
        maxBounds:
          [
            [ 54.14313233476031 , 13.581504821777346 ] ,
            [ 54.04245742542455 , 13.176727294921877 ]
          ]
        ,
        maxBoundsViscosity: 1.0 ,
        maxZoom: 18 ,
        minZoom: 12 ,
      }
  }

const adjust_initial_zoom = () =>
  {
    let my_numericKeys =
      Object.keys( appSettings.leaflet.initialZoom.breakpoints )
        .map(
          ( entry:string ) => Number( entry )
        )
        .sort( ( a , b ) => b - a )
    ;
    for( let i = 0 ; i < my_numericKeys.length ; i++ ) {
      if( window.screen.availWidth > my_numericKeys[ i ] ){
        // @ts-ignore
        return appSettings.leaflet.initialZoom.breakpoints[ my_numericKeys[ i ] ] ;
      }
    }
  }

const mapSettings =
  {
    leaflet:
      {
        center: appSettings.leaflet.center ,
        initialZoom: adjust_initial_zoom() ,
        maxBounds: appSettings.leaflet.maxBounds ,
        maxBoundsViscosity: appSettings.leaflet.maxBoundsViscosity ,
        maxZoom: appSettings.leaflet.maxZoom ,
        minZoom: appSettings.leaflet.minZoom ,
      }
  }

export const TarifEdit: React.FC = () => {
  const { appTarif , appTarifChange , appTarifeListeFetchAnewChange } = useContext(DataContext);

  const contentRef = createRef<HTMLIonContentElement>();

  const [ boolTarifFetchAnew , setBoolTarifFetchAnew ] = useState( false ) ;
  const [ intFetchTime , setIntFetchTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;

  const [ renderMap , setRenderMap ] = useState( false );

  const ref_flaeche = useRef() ;
  const refsToastEditor = Array() ;
  const ref_text = useRef() ; refsToastEditor.push( ref_text ) ;
  
  const mapFlaecheZoomInit = 15 ;
  const mapData = {
    center: Array() ,
    centerInit: [ 54.0928368 , 13.3792729 ] ,
    zoom: Array() ,
    zoomInit: 15 ,
  } ;
  mapData[ 'center' ] = useState( mapData.centerInit ) ;
  mapData[ 'zoom' ] = useState( mapData.zoomInit ) ;

  const tarif =
    {
      id: Array() ,
      info:
        {
          aktiv: Array() ,
          bezeichnung: Array() ,
        }
      ,
      texte:
        {
          text: Array() ,
        }
      ,
      geo:
        {
          flaeche: Array() ,
          flaecheNumber: Array() ,
        }
    }
  ;
  tarif.id= useState( appTarif ) ;
  tarif.info.aktiv = useState( '' ) ;
  tarif.info.bezeichnung = useState( '' ) ;

  tarif.texte.text = useState( ' ' ) ;

  tarif.geo.flaeche = useState( Array() ) ;
  tarif.geo.flaecheNumber = useState( 0 ) ;

  const resetTarif = () =>
    {
      tarif.id[ 1 ]( appTarif ) ;
      Object.keys( tarif.info )
        .forEach(
          ( entry:any ) =>
            {
              if( entry === 'aktiv' ) {
                // @ts-ignore
                tarif.info[ entry ][ 1 ]( 'true' )
                return ;
              }
              // @ts-ignore
                tarif.info[ entry ][ 1 ]( '' )
            }
        )
      ;
      Object.keys( tarif.texte )
        .forEach(
          ( entry:any ) =>
            {
              // @ts-ignore
              tarif.texte[ entry ][ 1 ]( ' ' )
            }
        )
      ;
      refsToastEditor
        .forEach(
          ( entry:any ) =>
            {
              entry.current.getInstance()?.setMarkdown( ' ' ) ;              
            }
        )
      tarif.geo.flaeche[ 1 ]( Array() ) ;
      tarif.geo.flaecheNumber[ 1 ]( 0 ) ;
      contentRef.current?.scrollToTop(500);
    }

  const setTarifFromDB = () =>
    {
      tarif.id[ 1 ]( appTarif ) ;
      if( objFetchResult.data[ 0 ] ) {
        let my_handles =
          [
            [
              'info' , 'aktiv'
            ] ,
            [
              'info' , 'bezeichnung'
            ] ,
            [
              'texte' , 'text'
            ] ,
            [
              'geo' , 'flaeche'
            ] ,
          ]
        ;
        my_handles
          .forEach(
            ( entry:any ) =>
              {
                if( entry[ 0 ] === 'texte' ) {
                  let my_string = objFetchResult.data[ 0 ][ 'tarif_' + entry[ 1 ] ] ;
                  // @ts-ignore
                  // my_string = my_string.substring( 1 , my_string.length - 1 ) ;
                  if( entry[ 1 ] === 'text' ) {
                    // @ts-ignore
                    ref_text.current.getInstance()?.setMarkdown( my_string ) ;
                  }
                  // @ts-ignore
                  tarif[ entry[ 0 ] ][ entry[ 1 ] ][ 1 ]( my_string ) ;
                  return ;
                }
                if( entry[ 0 ] === 'geo' ) {
                  // @ts-ignore
                  tarif[ entry[ 0 ] ][ entry[ 1 ] ][ 1 ]( JSON.parse( objFetchResult.data[ 0 ][ 'tarif_' + entry[ 1 ] ] ) ) ;
                  return ;
                }
                  // @ts-ignore
                tarif[ entry[ 0 ] ][ entry[ 1 ] ][ 1 ]( objFetchResult.data[ 0 ][ 'tarif_' + entry[ 1 ] ] ) ;
              }
          )
        ;
        contentRef.current?.scrollToTop(500);
      }
    }

  const initToastEditor = ( state:any, ref:any ) =>
    {
      return(
        <Editor
          initialValue = { state[ 0 ] }
          height="400px"
          initialEditType='wysiwyg'
          language = "de-DE"
          onChange = { () => setFormState( state[ 1 ] , ref.current.getInstance()?.getMarkdown() ) }
          useCommandShortcut={true}
          usageStatistics={false}
          // @ts-ignore
          ref = { ref }
        />
      )
    }

  async function sendJSON() {
    const doFetch = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append( 'Authorization' , `Bearer ${ appGlobalsUserToken }` ) ;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data )
      };
      await fetch('https://api.parkraumpilot.de/api/tarife/set_tarif.php', requestOptions)
      .then( response => console.log( response ) )
    }
    let data = {
      tarif_id: tarif.id[ 0 ] ,
      tarif_aktiv: tarif.info.aktiv[ 0 ] ,
      tarif_kunde: appGlobalsUser.usr_kunde ,
      tarif_bezeichnung: tarif.info.bezeichnung[ 0 ] ,
      tarif_text: JSON.stringify( tarif.texte.text[ 0 ] ) ,
      tarif_flaeche: JSON.stringify( tarif.geo.flaeche[ 0 ] ) ,
    }
    doFetch() ;
  }

  const saveTarif = () =>
    {
      sendJSON() ;
      appTarifeListeFetchAnewChange( true ) ;
    }

  const setFormState = ( setter:Function , value:any ) =>
    {
      setter( value ) ;
    }

  const resetTarifFlaeche = ( myTarifFlaeche:any ) =>
    {
      tarif.geo.flaeche[ 1 ]( myTarifFlaeche ) ;
        /** "tarif.geo.flaeche[ 0 ]" ist ein Array, React kann Arrays nicht vergleichen, deshalb noch ein vergleichbarer State "tarif.geo.flaecheNumber[ 0 ]" dazu */
      tarif.geo.flaecheNumber[ 1 ]( myTarifFlaeche.length ) ;
    }
  ;

  const GetClickCoords = () =>
    {
      const mapEvents = useMapEvents({
        click(e){
          let myTarifFlaeche = tarif.geo.flaeche[ 0 ] ;
          myTarifFlaeche.push( [ e.latlng.lat , e.latlng.lng ] ) ;
          resetTarifFlaeche( myTarifFlaeche ) ;
        } ,
        moveend(){
          mapData.center[ 1 ]( mapEvents.getCenter() ) ;
        } ,
        zoomend(){
          mapData.zoom[ 1 ]( mapEvents.getZoom() ) ;
        } ,
      }) ;
      return (
        <Polygon
          pathOptions = { { color: 'red' } }
          positions = { tarif.geo.flaeche[ 0 ] }
        />
      ) ;
    }

  // s. https://gis.stackexchange.com/questions/127286/home-button-leaflet-map/127383
  // s. Suche "Home" in https://leafletjs.com/plugins.html
  const MapHomeButton = ( id:string ) =>
    {
      const context = useLeafletContext() ;
      const mapObject = context.map ;
      useEffect(
        () =>
          {
            if( ! document.getElementById( id ) ) {
              L
                .easyButton(
                  svgFaHouseEdited ,
                  function(
                    btn ,
                    map
                  )
                    {
                      map
                        .setView(
                          // @ts-ignore
                          mapSettings.leaflet.center ,
                          mapSettings.leaflet.initialZoom
                        )
                      ;
                    }
                    ,
                  'Startansicht' ,
                  id
                )
                .addTo( mapObject )
              ;
              // @ts-ignore
              let goal:any = document.getElementById( id ).style
              goal.color = 'black' ;
              goal.width = '30px' ;
            }
          }
        ,
        [ mapObject ]
      ) ;
      return null ;
    }

  const MapHomeButtonFlaeche = () =>
    {
      return MapHomeButton( 'easyButtonHomeFlaeche_tarif' ) ;
    }

  useLayoutEffect(
      function initMap() {
        setTimeout(
          () => setRenderMap(true) ,
          10
        ) ;
      }
      ,
      []
    )
  
    useEffect(
    function setTarifData() {
      resetTarif() ; 
      if( appTarif !== 'neu' ) {
        setBoolTarifFetchAnew( true ) ;
      }
    }
    ,
    [ appTarif ]
  ) ;

  useEffect(
    function getTarifData() {
      if( boolTarifFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/tarife/' ,
          'get_tarif.php?tarif_id=' + appTarif ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchTime
        ) ;
        setBoolTarifFetchAnew( false ) ;
      }
    } ,
    [ boolTarifFetchAnew ]
  ) ;

  useEffect(
    function renewTarifView() {
      setTarifFromDB() ;
    } ,
    [ intFetchTime ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Tarif erstellen
          </IonTitle>
          {/* <IonButton
            onClick = { () => saveTarif() }
            slot = "end"
          >
            Speichern
          </IonButton> */}
          <IonButton
            onClick = { () => { saveTarif() ; resetTarif() ; appTarifChange( 'neu' ) ; } }
            routerLink = { '/PageTarife' }
            slot = "end"
          >
            Speichern und Schließen
          </IonButton>
          <IonButton
            onClick = { () => { resetTarif() ; appTarifChange( 'neu' ) } }
            routerLink = { '/PageTarife' }
            slot = "end"
          >
            Abbrechen
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent ref = { contentRef }>
        <IonCard key = "info" >
          <IonCardHeader>
            <IonCardTitle>
              Stammdaten
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem
                className='item'
                lines="none"
              >
                <IonLabel slot="">
                  {
                    tarif.info.aktiv[ 0 ] === 'true'
                      ?
                        'aktiv (wird angezeigt) '
                      :
                        'inaktiv (wird nicht angezeigt) '
                  }
                </IonLabel>
                <IonToggle
                  checked = { tarif.info.aktiv[ 0 ] }
                  onIonChange = { ( e ) => setFormState( tarif.info.aktiv[ 1 ] , e.target.checked ? 'true' : 'false' ) }
                  slot="start"
                />
              </IonItem>
              <IonItem className='item'>
                <IonLabel
                  position="stacked"
                >
                  Bezeichnung
                </IonLabel>
                <IonInput
                  onIonChange = { ( e ) => setFormState( tarif.info.bezeichnung[ 1 ] , e.target.value ) }
                  value = { tarif.info.bezeichnung[ 0 ] }
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Text
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {
              initToastEditor( tarif.texte.text , ref_text )
            }
          </IonCardContent>
        </IonCard>
        <IonCard key = "flaeche" >
          <IonCardHeader>
            <IonCardTitle>
              Fläche (optional)
            </IonCardTitle>
            <IonCardSubtitle>
              Eckpunkte der Fläche in Karte klicken (mindestens drei Punkte erforderlich)
            </IonCardSubtitle>
            <IonToolbar>
              <IonButton
                disabled = { tarif.geo.flaecheNumber[ 0 ] === 0 ? true : false }
                onClick =
                  {
                    () =>
                      {
                        let myTarifFlaeche = tarif.geo.flaeche[ 0 ] ;
                        let myTarifFlaecheNumber = tarif.geo.flaecheNumber[ 0 ] ;
                        myTarifFlaeche.pop() ;
                        tarif.geo.flaeche[ 1 ]( myTarifFlaeche ) ;
                        tarif.geo.flaecheNumber[ 1 ]( myTarifFlaecheNumber - 1 ) ;
                      }
                  }
              >
                Letzten Punkt zurücksetzen 
              </IonButton>
              <IonButton
                disabled = { tarif.geo.flaecheNumber[ 0 ] === 0 ? true : false }
                onClick =
                  {
                    () =>
                      {
                        tarif.geo.flaeche[ 1 ]( [] ) ;
                        tarif.geo.flaecheNumber[ 1 ]( 0 ) ;
                      }
                  }
              >
                Alle Punkte zurücksetzen 
              </IonButton>
            </IonToolbar>
          </IonCardHeader>
          <IonCardContent>
            {
              renderMap
              &&
              <MapContainer
                id = 'tarife_flaeche'
                key = 'tarife_flaeche'
                center = { mapData.center[ 0 ] }
                // @ts-ignore
                ref = { ref_flaeche }
                style =
                  {
                    {
                      width: '100%' ,
                      height: '500px' ,
                      position: 'relative'
                    }
                  }
                zoom = { mapData.zoom[ 0 ] }
              >
                <MapHomeButtonFlaeche/>
                <TileLayer
                  attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url = "https://karten.solufind.de/hot/{z}/{x}/{y}.png"
                />
                  <GetClickCoords/>
              </MapContainer>}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
