import { Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";

import * as Pages from './pages/index'
import './components/CompKarte.css'
import { ArtikelEdit } from './pages/artikel_edit'
import { BaustelleEdit } from './pages/baustelle_edit'
import { ParkraumEdit } from './pages/parkraum_edit'
import { SeiteEdit } from './pages/seite_edit'
import { TarifEdit } from './pages/tarif_edit'


import { IonReactRouter } from "@ionic/react-router";



/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import PageLogin from "./pages/PageLogin";
import { useEffect, useState } from "react";

import { DataContext } from "./context/DataContext";
import AppContext from "./context/AppContext";
import { CompMenu } from "./components/CompMenu";
import { appGlobalsUserTokenChange } from "./appGlobals";

setupIonicReact();

appGlobalsUserTokenChange( '' ) ;

const generatedRoutes =
  Pages.pagesList
    .map(
      ( menueItem:any ) => {
        return (
          <Route
            key = { menueItem[ 0 ] }
            // @ts-ignore
            component = { Pages[ menueItem[ 0 ] ] }
            path = { '/' + menueItem[ 0 ] }
          />
        )
      }
    )
;

const App: React.FC = () => {
  const [ appArtikel , setAppArtikel ] = useState( 'neu' ) ;
  const [ appArtikelListeFetchAnew , setAppArtikelListeFetchAnew ] = useState( false ) ;
  const [ appBaustelle , setAppBaustelle ] = useState( 'neu' ) ;
  const [ appBaustellenListeFetchAnew , setAppBaustellenListeFetchAnew ] = useState( false ) ;
  const [ appLoggedIn , setAppLoggedIn ] = useState( false ) ;
  const [ appParkraum , setAppParkraum ] = useState( 'neu' ) ;
  const [ appParkraumListeFetchAnew , setAppParkraumListeFetchAnew ] = useState( false ) ;
  const [ appSeite , setAppSeite ] = useState( 'neu' ) ;
  const [ appSeitenListeFetchAnew , setAppSeitenListeFetchAnew ] = useState( false ) ;
  const [ appTarif , setAppTarif ] = useState( 'neu' ) ;
  const [ appTarifeListeFetchAnew , setAppTarifeListeFetchAnew ] = useState( false ) ;

  let dataContextValue = {
    appArtikel: appArtikel,
    appArtikelChange: setAppArtikel,
    appArtikelListeFetchAnew: appArtikelListeFetchAnew,
    appArtikelListeFetchAnewChange: setAppArtikelListeFetchAnew,
    appBaustelle: appBaustelle,
    appBaustelleChange: setAppBaustelle,
    appBaustellenListeFetchAnew: appBaustellenListeFetchAnew,
    appBaustellenListeFetchAnewChange: setAppBaustellenListeFetchAnew,
    appLoggedInChange: setAppLoggedIn,
    appParkraum: appParkraum,
    appParkraumChange: setAppParkraum,
    appParkraumListeFetchAnew: appParkraumListeFetchAnew,
    appParkraumListeFetchAnewChange: setAppParkraumListeFetchAnew,
    appSeite: appSeite,
    appSeiteChange: setAppSeite,
    appSeitenListeFetchAnew: appSeitenListeFetchAnew,
    appSeitenListeFetchAnewChange: setAppSeitenListeFetchAnew,
    appTarif: appTarif,
    appTarifChange: setAppTarif,
    appTarifeListeFetchAnew: appTarifeListeFetchAnew,
    appTarifeListeFetchAnewChange: setAppTarifeListeFetchAnew,
  };

  return (
    <DataContext.Provider value={dataContextValue}>
      <IonApp>
      {
        ! appLoggedIn
          ?
            <PageLogin/>
          :
            <IonReactRouter>
                <AppContext />
              <IonSplitPane contentId="main">
                <CompMenu />
                <IonRouterOutlet id="main">
                  { generatedRoutes }
                  {/* <Redirect exact from="/" to="/Admin" /> */}
                  <Route
                    component = { ArtikelEdit }
                    path = { '/artikel_erstellen' }
                  />
                  <Route
                    component = { BaustelleEdit }
                    path = { '/baustelle_erstellen' }
                  />
                  <Route
                    component = { ParkraumEdit }
                    path = { '/parkraum_erstellen' }
                  />
                  <Route
                    component = { SeiteEdit }
                    path = { '/seite_erstellen' }
                  />
                  <Route
                    component = { TarifEdit }
                    path = { '/Tarif_erstellen' }
                  />
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
      }
    </IonApp>
    </DataContext.Provider>
  );
};

export default App;
