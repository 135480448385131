import { IonButton, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import { CompPageItem } from '../components/CompPageItem';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';

export const PageBaustellen: React.FC = () => {
  const {
    appBaustelleChange ,
    appBaustellenListeFetchAnew ,
    appBaustellenListeFetchAnewChange
  } = useContext(DataContext);

  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ intFetchSetTime , setIntFetchSetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;
  const [ generatedItems , setGeneratedItems  ] = useState( 'laden ...' ) ;

  const makeLabel = ( id:number , main:string, von:string , bis:string ) =>
    {
      let my_label = main ? main : 'unbenannt (interne ID: ' + id + ')' ;
      my_label += ' (' + format_timespan( von , bis ) + ')' ;
      return my_label ;
    }

  const format_timespan = ( from:string , to:string ) =>
    {
      return(
        new Date( from ).toLocaleDateString()
        + ' bis '
        + new Date( to ).toLocaleDateString()
      ) ;
    }

  useEffect(
    function initComponent() {
      appBaustellenListeFetchAnewChange( true ) ;
    } ,
    []
  ) ;

  useEffect(
    function getBaustellenListeData() {
      if( appBaustellenListeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/baustellen/' ,
          'get_baustellen.php?kunde_id=' + appGlobalsUser.usr_kunde + '&all=1' ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchGetTime
        ) ;
        appBaustellenListeFetchAnewChange( false ) ;
      }
    } ,
    [ appBaustellenListeFetchAnew ]
  ) ;

  useEffect(
    function renewBaustellenListeView() {
      let my_Baustellen = Array() ;
      let my_generatedItems:any = 'laden ...' ;
      if( objFetchResult.data.length > 0 ) {
        my_Baustellen = objFetchResult.data
          .map(
            (entry:any ) =>
              [
                entry[ 0 ] ,
                entry[ 2 ] ,
                entry[ 3 ] ,
                entry[ 4 ] ,
                entry[ 7 ]
              ]
          )
        ;
        my_Baustellen
          .sort(
            ( a:any , b:any ) => 
              {
                if ( a[ 1 ] > b[ 1 ] ) { return 1 ; }
                if ( a[ 1 ] < b[ 1 ]  ) { return -1 ; }
                return 0 ;
              }
          )
         my_generatedItems =
          <IonList>
            {
              my_Baustellen
                .map(
                  ( entry:any ) =>
                    {
                      return(
                        <CompPageItem
                          key = { 'baustelle_' + entry[ 0 ] }
                          itemID = { entry[ 0 ] }
                          itemLabel = { makeLabel( entry[ 0 ] , entry[ 1 ] , entry[ 2 ] , entry[ 3 ] ) }
                          pageKey = 'baustelle'
                          appItemSelectChange = { appBaustelleChange }
                          itemSetData =
                            {
                              {
                                baustelle_id: entry[ 0 ] ,
                                baustelle_aktiv: entry[ 4 ] === 'true' ? 'false' : 'true'
                              }
                            }
                            itemActiveState = { entry[ 4 ] }
                            apiUrl = "https://api.parkraumpilot.de/api/"
                            apiCall = "baustellen/set_baustelle.php"
                            setIntFetchSetTime = { setIntFetchSetTime }
                        />
                      )
                    }
                )                 
            }
          </IonList>
         ;
      } else {
        my_generatedItems = 'keine Einträge vorhanden' ;
      }
      setGeneratedItems( my_generatedItems ) ;
    } ,
    [ intFetchGetTime ]
  ) ;

  useEffect(
    function renewBaustellenListeData() {
      if( intFetchSetTime > 0 ) {
        appBaustellenListeFetchAnewChange( true ) ;
      }
    } ,
    [ intFetchSetTime ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Baustellen
            {
              intFetchGetTime !== 0
                ?
                  ' Stand: ' + new Date( intFetchGetTime ).toLocaleString()
                :
                  null
            }
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButton
            onClick = {
              () =>
                {
                  appBaustellenListeFetchAnewChange( true ) ;
                }
            }
          >
            Liste aktualisieren
          </IonButton>
          <IonButton
            onClick = {
              () =>
                {
                  appBaustelleChange( 'neu' ) ;
                }
            }
            routerLink = "/baustelle_erstellen"
          >
            Neue Baustelle erstellen
          </IonButton>
        </IonToolbar>
      </IonHeader>
    <IonContent>
      <IonCard key="BaustellenListe">
        <IonCardContent
        >
          { generatedItems }
        </IonCardContent>
      </IonCard>
    </IonContent>
    </IonPage>
  );
};
