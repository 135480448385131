/**
 *  setDataByApi - Datenübermittlung an angegebenen Endpoint
 * 
 * @param apiUrl        - z.B. "https://datamehr-backend.saint-online.de/api/" 
 * @param apiCall       - z.B. "lieferanten/set_lieferanten.php?betriebe_id=" 
 * @param authToken     - optional: Authentifizierungstoken aus Login
 * @param dataObj       - zu speichernde Daten als JS-Objekt (nicht JSON!)
 * @param setResponse   - Funktion zum Setzen der Fetch-Status-Meldung (typischerweise useState-Funktion)
 * @param setFetchtime  - Funktion zum Zwischenspeichern des letzten (erfolgreichen) Fetch-Zeitpunkts
 */
 export const setDataByApi = async ( apiUrl:string , apiCall:string , authToken:string = '' , dataObj:any = '' , setResponse:Function , setFetchtime:Function ) => {
  setResponse( 100 ) ;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append( 'Authorization' , `Bearer ${ authToken }` ) ;
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify( dataObj )
  };
  await
    fetch
      (
        apiUrl + apiCall
        ,
        requestOptions
      )
    .then(
      ( response ) =>
        {
          setResponse( response.status ) ;
          if( response.ok ) {
            setFetchtime( new Date().valueOf() ) ;

          } else {
            throw( response.status + ' ' + response.statusText ) ;
          }
        }
    )
    .catch(
      ( error ) =>
        { 
          console.log( 'Fehler setDataByApi:' , error ) ;
          alert( 'Fehler beim Speichern - bitte noch einmal versuchen') ;
        }
    )
    ;
}

