import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import axios from 'axios';
import jwt from 'jwt-decode';
import { useContext, useState } from 'react';
import { appGlobalsUserChange, appGlobalsUserTokenChange } from '../appGlobals';
import { DataContext } from '../context/DataContext';

export default function PageLogin()  {
    const { appLoggedInChange } = useContext(DataContext);

const [anmeldename, setAnmeldename] = useState(String)
const [password, setPassword] = useState(String)
const handleLogin = () => {
  const loginData = {
    // abwärtskompatibel: Feldname "email" im Backend
    email: anmeldename,
    password: password,
  };
  const api = axios.create(
    {
      baseURL: 'https://api.parkraumpilot.de/api/user/login.php' ,
    }
  );
  api
    .post( "/login" , loginData )
    .then(
      ( res ) =>
        {
          appGlobalsUserTokenChange( res.data ) ;
          appGlobalsUserChange( jwt( res.data ) ) ;
          appLoggedInChange( true ) ;
        }
    )
    .catch(
      ( error ) =>
        {
        alert( 'Login-Fehler - bitte Anmeldedaten prüfen und erneut versuchen' ) ;
      }
    );
};

  return (
    <IonPage
     onKeyUp = { ( e ) => { if( e.key === 'Enter' ) { handleLogin() ; } } }
    >
    <IonHeader>
  <IonToolbar>
    <IonTitle>{ document.title }</IonTitle>
  </IonToolbar>
</IonHeader>
<IonContent>
    <IonCard>
        <IonCardContent>
            <IonList>
                <IonItem className='item'>
                    <IonLabel
                        position="stacked"
                    >
                        Anmeldename (Ihre E-Mail-Adresse)
                    </IonLabel>
                    <IonInput
                        type="text"
                        value={anmeldename}
                        onIonChange={(e) => setAnmeldename(e.detail.value!)}
                    />
                </IonItem>
                <IonItem className='item'>
                    <IonLabel
                        position="stacked"
                    >
                        Passwort
                    </IonLabel>
                    <IonInput
                        type="password"
                        value={password}
                        onIonChange={(e) => setPassword(e.detail.value!)}
                        >
                    </IonInput>
                </IonItem>
                <IonButton type = 'submit' style = { {  height: '6em' } } expand="block" onClick={handleLogin}>
                Einloggen
                </IonButton>
            </IonList>
        </IonCardContent>
    </IonCard>
</IonContent>
</IonPage>
  );
};
