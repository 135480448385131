import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonItem, IonLabel, IonList, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';
import { setDataByApi } from '../functions/setDataByApi';

export const ParkraumTarife: React.FC = () => {
  const {
    appParkraum
  } = useContext(DataContext);

  const [ boolParkraumTarifeFetchAnew , setBoolParkraumTarifeFetchAnew ] = useState( false ) ;
  const [ boolTarifeFetchAnew , setBoolTarifeFetchAnew ] = useState( false ) ;
  const [ generatedItems , setGeneratedItems  ] = useState( <p>laden ...</p> ) ;
  const [ intFetchTimeParkraumTarife , setIntFetchTimeParkraumTarife ] = useState( 0 ) ;
  const [ objFetchResultParkraumTarife , setObjFetchResultParkraumTarife ] = useState( { data: [] } ) ;
  const [ intFetchTimeTarife , setIntFetchTimeTarife ] = useState( 0 ) ;
  const [ objFetchResultTarife , setObjFetchResultTarife ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;

  const resetParkraumTarife = () =>
    {
      setGeneratedItems( <p>laden ...</p> ) ;
      setObjFetchResultParkraumTarife( { data: [] } ) ;
    }

  const onParkraumTarifeChange = ( tarif:number ) =>
    {
      setDataByApi(
        'https://api.parkraumpilot.de/api/parkraeume/' ,
        'set_parkraum_tarif.php' ,
        appGlobalsUserToken ,
        {
          parkraum_tarif_kunde: appGlobalsUser.usr_kunde ,
          parkraum_tarif_parkraum: appParkraum ,
          parkraum_tarif_tarif: tarif
        } ,
        () => {} ,
        () => {}
      )
      ;
    }

  useIonViewWillEnter(
    function () {
      console.log( 'IonViewWillEnter' ) ;
      setBoolTarifeFetchAnew( true ) ;
    }
  )

  useIonViewDidLeave(
    function () {
      console.log( 'IonViewDidLeave' ) ;
      resetParkraumTarife() ; 
    }
  )

  useEffect(
    function getTarife() {
      if( boolTarifeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/tarife/' ,
          'get_tarife.php?kunde_id=' + appGlobalsUser.usr_kunde + '&all=1' ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResultTarife ,
          setIntFetchTimeTarife ,
          '' ,
          {
            fields:
              [
                'tarif_id' ,
                'tarif_bezeichnung' ,
                'tarif_aktiv'
              ]
          }
        ) ;
        setBoolTarifeFetchAnew( false ) ;
      }
    }
    ,
    [ boolTarifeFetchAnew ]
  ) ;

  useEffect(
    function getParkraumTarife() {
      if( appParkraum !== 'neu' ) {
        setBoolParkraumTarifeFetchAnew( true ) ;
      } else {
        setGeneratedItems(
          <IonList>
            <IonItem lines = "none" >
              <IonLabel>
                Ein neu angelegter Parkraum muss zuerst gespeichert werden, damit Tarife zugeordnet werden können.
              </IonLabel>
            </IonItem>
          </IonList>
        ) ;
      }
    }
    ,
    [ intFetchTimeTarife ]
  ) ;

  useEffect(
    function getParkraumTarife() {
      if( boolParkraumTarifeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/parkraeume/' ,
          'get_parkraum_tarife.php?parkraum_id=' + appParkraum ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResultParkraumTarife ,
          setIntFetchTimeParkraumTarife ,
          '' ,
          {
            fields:
              [
                'tarif_id' ,
                'tarif_bezeichnung' ,
                'tarif_aktiv'
              ]
          }
        ) ;
        setBoolParkraumTarifeFetchAnew( false ) ;
      }
    }
    ,
    [ boolParkraumTarifeFetchAnew ]
  ) ;

  useEffect(
    function renewTarifeList() {
      let my_ParkraumTarife = Array() ;
      let my_Tarife = Array() ;
      let my_generatedItems:any = <p>laden ...</p> ;
      if( objFetchResultTarife.data.length > 0 ) {
        my_ParkraumTarife =
          objFetchResultParkraumTarife.data
            .map(
              ( entry:any ) => entry.parkraum_tarif_tarif
            )
          ;
        my_Tarife = objFetchResultTarife.data ;
        my_Tarife
          .sort(
            ( a:any , b:any ) => 
              {
                if ( a[ 1 ] > b[ 1 ] ) { return 1 ; }
                if ( a[ 1 ] < b[ 1 ]  ) { return -1 ; }
                return 0 ;
              }
          )
        my_generatedItems =
          <IonList>
            {
              my_Tarife
                .map(
                  ( entry:any ) =>
                    {
                      return(
                        <IonItem
                          lines = "none"
                        >
                          <IonCheckbox
                            checked = { my_ParkraumTarife.includes( entry[ 0 ] ) ? true : false  }
                            disabled = { entry[ 2 ] === 'true' ? false : true }
                            onIonChange = { () => onParkraumTarifeChange( entry[ 0 ] ) }
                            slot = "start"
                          />
                          <IonLabel>
                            { entry[ 1 ] } { entry[ 2 ] === 'false' ? ' - deaktiviert' : '' }
                          </IonLabel>
                        </IonItem>
                      )
                    }
                )                 
            }
          </IonList>
        ;
      } else {
        my_generatedItems = 'keine Einträge vorhanden' ;
      }
      setGeneratedItems( my_generatedItems ) ;
    } ,
    [ intFetchTimeParkraumTarife ]
  ) ;

  return (
    <IonCard key = "tarife" >
      <IonCardHeader>
        <IonCardTitle>
          Tarife & Gebühren
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        { generatedItems }
      </IonCardContent>
    </IonCard>
  );
};
