import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import { CompPageItem } from '../components/CompPageItem';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';
import { setDataByApi } from '../functions/setDataByApi';

export const PageParkraeume: React.FC = () => {
  const { appParkraumChange , appParkraumListeFetchAnew , appParkraumListeFetchAnewChange } = useContext(DataContext);

  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ intFetchSetTime , setIntFetchSetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;
  const [ generatedItems , setGeneratedItems  ] = useState( 'laden ...' ) ;

  const makeLabel = ( id:number , main:string, sub:string ) =>
    {
      let my_label = main ? main : 'unbenannt (interne ID: ' + id + ')' ;
      if( sub ) {
        my_label += ' (' + sub + ')' ;
      }
      return my_label ;
    }

  useEffect(
    function initComponent() {
      appParkraumListeFetchAnewChange( true ) ;
    } ,
    []
  ) ;

  useEffect(
    function getParkraumListeData() {
      if( appParkraumListeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/parkraeume/' ,
          'get_parkraeume.php?kunde_id=' + appGlobalsUser.usr_kunde + '&all=1' ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchGetTime
        ) ;
        appParkraumListeFetchAnewChange( false ) ;
      }
    } ,
    [ appParkraumListeFetchAnew ]
  ) ;

  useEffect(
    function renewParkraumListeView() {
      let my_parkraeume = Array() ;
      let my_generatedItems:any = 'laden ...' ;
      if( objFetchResult.data.length > 0 ) {
        my_parkraeume = objFetchResult.data
          .map(
            (entry:any ) => [ entry[ 0 ] , entry[ 2 ] , entry[ 4 ] , entry[ 14 ] ]
          )
        ;
        my_parkraeume
          .sort(
            ( a:any , b:any ) => 
              {
                if ( a[ 1 ] > b[ 1 ] ) { return 1 ; }
                if ( a[ 1 ] < b[ 1 ]  ) { return -1 ; }
                return 0 ;
              }
          )
        my_generatedItems =
          <IonList>
          {
            my_parkraeume
              .map(
                ( entry:any ) =>
                  {
                    return(
                      <CompPageItem
                        key = { 'parkraum_' + entry[ 0 ] }
                        itemID = { entry[ 0 ] }
                        itemLabel = { makeLabel( entry[ 0 ] , entry[ 1 ] , entry[ 2 ] ) }
                        pageKey = 'parkraum'
                        appItemSelectChange = { appParkraumChange }
                        itemSetData =
                          {
                            {
                              parkraum_id: entry[ 0 ] ,
                              parkraum_aktiv: entry[ 3 ] === 'true' ? 'false' : 'true'
                            }
                          }
                          itemActiveState = { entry[ 3 ] }
                          apiUrl = "https://api.parkraumpilot.de/api/"
                          apiCall = "parkraeume/set_parkraum.php"
                          setIntFetchSetTime = { setIntFetchSetTime }
                      />
                    )
                  }
              )                 
          }
          </IonList>
        ;
      } else {
        my_generatedItems = 'keine Einträge vorhanden' ;
      }
      setGeneratedItems( my_generatedItems ) ;
    } ,
    [ intFetchGetTime ]
  ) ;

  useEffect(
    function renewParkraumListeView() {
      if( intFetchSetTime > 0 ) {
        appParkraumListeFetchAnewChange( true ) ;
      }
    } ,
    [ intFetchSetTime ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Parkräume
            {
              intFetchGetTime !== 0
                ?
                  ' Stand: ' + new Date( intFetchGetTime ).toLocaleString()
                :
                  null
            }
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButton
            onClick = {
              () =>
                {
                  appParkraumListeFetchAnewChange( true ) ;
                }
            }
          >
            Liste aktualisieren
          </IonButton>
          <IonButton
            onClick = {
              () =>
                {
                  appParkraumChange( 'neu' ) ;
                }
            }
            routerLink = "/parkraum_erstellen"
          >
            Neuen Parkraum erstellen
          </IonButton>
        </IonToolbar>
      </IonHeader>
    <IonContent>
      <IonCard key="parkraumliste">
        <IonCardContent
        >
          { generatedItems }
        </IonCardContent>
      </IonCard>
    </IonContent>
    </IonPage>
  );
};
