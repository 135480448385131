import { useContext } from 'react';
import { DataContext } from './DataContext';

const AppData: any = () => {
    const {
    } = useContext(DataContext);
    return null;
};

export default AppData;
