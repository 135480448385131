import { IonButton, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { appGlobalsUser, appGlobalsUserToken } from '../appGlobals';
import { CompPageItem } from '../components/CompPageItem';
import { DataContext } from '../context/DataContext';
import { getDataByApi } from '../functions/getDataByApi';

export const PageSeiten: React.FC = () => {
  const {
    appSeiteChange ,
    appSeitenListeFetchAnew ,
    appSeitenListeFetchAnewChange
  } = useContext(DataContext);

  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ intFetchSetTime , setIntFetchSetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;
  const [ generatedItems , setGeneratedItems  ] = useState( 'laden ...' ) ;

  const makeLabel = ( id:number , main:string, datum:string ) =>
    {
      let my_label = main ? main : 'unbenannt (interne ID: ' + id + ')' ;
      my_label += format_date( datum ) ;
      return my_label ;
    }
  
  const format_date = ( date:string ) => {
    let my_output = new Date( date ).toLocaleDateString() ;
    if( my_output.includes( '.' ) ) {
      return ' (' + my_output + ')' ;
    }
    return '' ;
  }

  useEffect(
    function initComponent() {
      appSeitenListeFetchAnewChange( true ) ;
    } ,
    []
  ) ;

  useEffect(
    function getSeitenListeData() {
      if( appSeitenListeFetchAnew ) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/seiten/' ,
          'get_seiten.php?kunde_id=' + appGlobalsUser.usr_kunde + '&all=1' ,
          appGlobalsUserToken ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchGetTime
        ) ;
        appSeitenListeFetchAnewChange( false ) ;
      }
    } ,
    [ appSeitenListeFetchAnew ]
  ) ;

  useEffect(
    function renewSeitenListeView() {
      let my_Seiten = Array() ;
      let my_generatedItems:any = 'laden ...' ;
      if( objFetchResult.data.length > 0 ) {
        my_Seiten = objFetchResult.data
          .map(
            (entry:any ) =>
              [
                entry[ 0 ] ,
                entry[ 2 ] ,
                entry[ 3 ] ,
                entry[ 7 ] ,
                entry[ 8 ]
              ]
          )
        ;
        my_generatedItems =
          <IonList>
            {
              my_Seiten
                .map(
                  ( entry:any ) =>
                    {
                      return(
                        <CompPageItem
                          key = { 'baustelle_' + entry[ 0 ] }
                          itemID = { entry[ 0 ] }
                          itemLabel = { makeLabel( entry[ 0 ] , entry[ 1 ] , entry[ 2 ] ) }
                          mkSortierung = { true }
                          pageKey = 'seite'
                          appItemSelectChange = { appSeiteChange }
                          itemSetData =
                            {
                              {
                                seite_id: entry[ 0 ] ,
                                seite_aktiv: entry[ 3 ] === 'true' ? 'false' : 'true' ,
                                seite_sortierung: entry[ 4 ]
                              }
                            }
                            itemActiveState = { entry[ 3 ] }
                            apiUrl = "https://api.parkraumpilot.de/api/"
                            apiCall = "seiten/set_seite.php"
                            setIntFetchSetTime = { setIntFetchSetTime }
                        />
                      )
                    }
                )                 
            }
          </IonList>
        ;
      } else {
        my_generatedItems = 'keine Einträge vorhanden' ;
      }
      setGeneratedItems( my_generatedItems ) ;
    } ,
    [ intFetchGetTime ]
  ) ;

  useEffect(
    function renewSeitenListeView() {
      if( intFetchSetTime > 0 ) {
        appSeitenListeFetchAnewChange( true ) ;
      }
    } ,
    [ intFetchSetTime ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Seiten
            {
              intFetchGetTime !== 0
                ?
                  ' Stand: ' + new Date( intFetchGetTime ).toLocaleString()
                :
                  null
            }
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButton
            onClick = {
              () =>
                {
                  appSeitenListeFetchAnewChange( true ) ;
                }
            }
          >
            Liste aktualisieren
          </IonButton>
          <IonButton
            onClick = {
              () =>
                {
                  appSeiteChange( 'neu' ) ;
                }
            }
            routerLink = "/seite_erstellen"
          >
            Neue Seite erstellen
          </IonButton>
        </IonToolbar>
      </IonHeader>
    <IonContent>
      <IonCard key="SeitenListe">
        <IonCardContent
        >
          { generatedItems }
        </IonCardContent>
      </IonCard>
    </IonContent>
    </IonPage>
  );
};
