/**
 * pages/index = "Sammel-Bereitstellung" von Page-Komponenten
 */

export let pagesList = Array() ;

pagesList.push( [ 'PageParkraeume' , 'Parkräume bearbeiten' ] ) ;
export { PageParkraeume } from './PageParkraeume' ;

pagesList.push( [ 'PageBaustellen' , 'Baustellen bearbeiten' ] ) ;
export { PageBaustellen } from './PageBaustellen' ;

pagesList.push( [ 'PageNews' , 'News bearbeiten' ] ) ;
export { PageNews } from './PageNews' ;

pagesList.push( [ 'PageSeiten' , 'Seiten bearbeiten' ] ) ;
export { PageSeiten } from './PageSeiten' ;

pagesList.push( [ 'PageTarife' , 'Tarife & Gebühren bearbeiten' ] ) ;
export { PageTarife } from './PageTarife' ;
